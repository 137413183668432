<template>
  <div>

  <div>
  <Carousel :value="items" :numVisible="3" :numScroll="3" :responsiveOptions="responsiveOptions">
      <template #header>
          <p class="p-mb-3 title">Tablica ogłoszeń</p>
      </template>
      <template #item="slotProps">
        <Card class="p-m-2 p-shadow-2">

    <template #title>
      <div class="title_card">
      {{slotProps.data.name}}
    </div>
        </template>
        <template #subtitle>
          <span class="p-ml-2">
          {{slotProps.data.date}}</span>
    </template>

    <template #content>
      {{slotProps.data.description.slice(0,100)}} ...
    </template>
    <template #footer>
      <div class="text-center">
      <Button icon="pi pi-external-link" label="Szczegóły" class="p-button-raised p-button-text p-button-sm" @click="openDialog(slotProps.data)"  />
    </div>
    </template>
</Card>

      </template>

  </Carousel>
<Dialog :header="dialog_text.title" :visible.sync="displayDialog" :style="{width: '50vw'}">
  <p v-html="dialog_text.description" class="p-m-3"></p>
<template #footer>
    <Button label="Zamknij" @click="closeDialog" autofocus class="p-button-sm" />
</template>
</Dialog>
</div>
</div>
</template>

<script>
export default {
	data() {
		return {
          items: null,
          displayDialog: false,
          dialog_text: {
            title: '',
            date: '',
            description: ''
          },
          responsiveOptions: [
				{
					breakpoint: '1024px',
					numVisible: 3,
					numScroll: 3
				},
				{
					breakpoint: '800px',
					numVisible: 2,
					numScroll: 2
				},
				{
					breakpoint: '600px',
					numVisible: 1,
					numScroll: 1
				}
			]
		  }
	},
	methods: {
    openDialog(text) {
        this.dialog_text.title = text.name;
        this.dialog_text.date = text.date;
        this.dialog_text.description = text.description_more;
        this.displayDialog = true;
        },
    closeDialog() {
      this.dialog_text.title = '';
      this.dialog_text.date = '';
      this.dialog_text.description = '';
      this.displayDialog = false;
        }
  },
	mounted() {
    axios
      .get('news')
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
	}
}
</script>
<style scoped>
.title_card {
  font-size: 17px;
  line-height: 1.25;
}
.title{
  font-size: 1.5em;
}
</style>
