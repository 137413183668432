<template>
<Toast />
<DataTable :value="allCalendarEvents" :scrollable="true" scrollHeight="500px" :lazy="true" class="p-my-3">
    <template #header>
        <div class="p-d-flex card">
            <span class="p-ml-5">Zajęcia</span>
            <Button label="Nowy" icon="pi pi-plus" class="p-mr-4 p-ml-auto p-button-success p-button-sm " @click="openNew" />
        </div>
    </template>
    <Column field="id" header="ID"></Column>
    <Column field="name" header="Nazwa"></Column>
    <Column field="enabled" header="Aktywne">
        <template #body="slotProps">
            <Checkbox v-model="slotProps.data.enabled" disabled="true" :binary="true" :readonly="true" :cancel="false" />
        </template>
    </Column>
    <Column>
        <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
        </template>
    </Column>

</DataTable>
<Dialog :visible.sync="eventDialog" :style="{width: '450px'}" header="Product Details" :modal="true" class="p-fluid">
    <div class="p-field">
        <label for="name">Nazwa</label>
        <InputText id="Nazwa" v-model.trim="event.name" required="true" autofocus :class="{'p-invalid': submitted && !event.name}" />
        <small class="p-invalid" v-if="submitted && !event.name">Name is required.</small>
    </div>
    <div class="p-field-checkbox" v-if="vEdit">
        <Checkbox id="enabled" v-model="event.enabled" :binary="true" />
        <label for="enabled">Aktywny</label>
    </div>
    <template #footer>
        <Button label="Anuluj" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
        <Button label="Zapisz" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
    </template>
</Dialog>
</template>

<script>
import {
    computed,
    ref
} from "vue";

export default {
    setup() {
        const eventDialog = ref(false)
        const submitted = ref(false)
        const vEdit = ref(false)
        const event = ref({
            name: ''
        })

        function openNew() {
            this.event = {};
            this.submitted = false;
            this.eventDialog = true;
        }

        function hideDialog() {
            this.eventDialog = false;
            this.submitted = false;
            this.vEdit = false;
        }

        function editProduct(event) {
            this.event = {
                ...event
            };
            this.eventDialog = true;
            this.vEdit = true;
        }

        function saveProduct() {
            if (this.vEdit != true) {
                this.submitted = true;
                if (this.event.name.trim()) {
                    this.$store.dispatch("addEvent", this.event)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sukces',
                        detail: 'Nowe Zajęcia dodane',
                        life: 3000
                    });
                }
            } else {
                if (this.event.name.trim()) {
                    this.$store.dispatch("updateEvent", this.event)
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sukces',
                        detail: 'Zajęcie zmienione',
                        life: 3000
                    });
                }
            }
            this.eventDialog = false;
            this.vEdit = false;
            this.event = {};
        }
        return {
            eventDialog,
            submitted,
            vEdit,
            event,
            openNew,
            hideDialog,
            editProduct,
            saveProduct
        }
    },
    mounted() {
        this.$store.dispatch("allAdminEvents");
    },
    computed: {
        allCalendarEvents() {
            return this.$store.getters.getEvents
        }
    },

}
</script>
