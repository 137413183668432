export default {
  state: {
    calendarevents: [],
    enabledevents: [],
    calendaritems: [],
    calendarroles: [],
       calendar: [],
       roles: [],
       permissions: [],
		   lecturers: [],
       users: [],
       files: [],
     },
  //-------------------------------------------------------------------------//
  mutations: {
    SET_CALENDARROLES(state,data) {
        state.calendarroles = data
      },
      SET_EVENTS(state,data) {
          state.calendarevents = data
       	},
      SET_ENABLED_EVENTS(state,data) {
          state.enabledevents = data
       	},
      SET_ITEMS(state,data) {
          state.calendaritems = data
       	},
      SET_ROLES(state,data) {
           state.roles = data
         },
      SET_LECTURERS(state,data) {
          state.lecturers = data
          },
      SET_CALENDAR(state,data) {
         return state.calendar = data
        	 },
       SET_PERMISSIONS(state,data) {
 			     return state.permissions = data
 		       },
      SET_FILES(state,data) {
           return state.files = data
           },
      SET_USERS(state,data) {
           return state.users = data
           },
      UPDATE_USER(state, user) {
             const index = state.users.map(o => o.id).indexOf(user.id);
                 state.users.splice(index, 1, user);
             },
      ADD_USER(state, data) {
          state.users = data
            },
     },
//-------------------------------------------------------------------------//
   actions: {
     getCalendarRoles(context){
       axios.get('/getcalendarroles')
            .then((response)=>{
               context.commit("SET_CALENDARROLES",response.data)
            })
           .catch(err => {
            console.log(err)
            })
     },
     getFiles(context){
            axios.get('/getfreefiles')
                 .then((response)=>{
                      context.commit("SET_FILES",response.data)
                             })
                 .catch(err => {
                     console.log(err)
                       })
               },
     getPermissions(context){
      			 axios.get('/getpermissions')
      						.then((response)=>{
      							 context.commit("SET_PERMISSIONS",response.data)
      						})
      					 .catch(err => {
      						console.log(err)
      						})
      		      },
     getCalendar(context){
     axios.get('/calendarview')
          .then((response)=>{
             context.commit("SET_CALENDAR",response.data)
          })
         .catch(err => {
          console.log(err)
          })
        },
     allRoles(context){
			 axios.get('/getroles')
						.then((response)=>{
							 context.commit("SET_ROLES",response.data)
						})
					 .catch(err => {
						console.log(err)
						})
		},
    allLecturers(context){
			 axios.get('/getlecturers')
						.then((response)=>{
							 context.commit("SET_LECTURERS",response.data)
						})
					 .catch(err => {
						console.log(err)
						})
		},

     //-------Items---------------------//
     async allAdminItems(context) {
       const res = await fetch("/calendaritems");
       const items = await res.json();
       context.commit("SET_ITEMS", items);
      },
      addItem({commit}, item) {
            axios.post('/calendaritems', item)
						.then(res => {
								commit('SET_ITEMS', res.data)
							})
                .catch(err => {
                console.log(err)
            })
        },
      updateItem({commit}, item){
           axios.put('/calendaritems/' + item.id , item)
                .then((response)=>{
                     commit("SET_ITEMS",response.data)
                          })
                .catch(err => {
                      console.log(err)
                          })
                },

     //-------Events---------------------//

     async enabledEvents(context) {
       const res = await fetch("/getevents");
       const events = await res.json();
       context.commit("SET_ENABLED_EVENTS", events);
      },
       async allAdminEvents(context) {
         const res = await fetch("/calendarevents");
         const events = await res.json();
         context.commit("SET_EVENTS", events);
        },
        addEvent({commit}, event) {
         axios.post('/calendarevents', event)
             .then(response => {
                   commit('SET_EVENTS', response.data)
     									})
             .catch(err => {
                   console.log(err)
                   })
             },
         updateEvent({commit}, event){
            axios.put('/calendarevents/' + event.id , event)
                 .then((response)=>{
                      commit("SET_EVENTS",response.data)
                           })
            		 .catch(err => {
                       console.log(err)
                           })
                 },
    //-------Users---------------------//
                 getUsers(context){
                   			 axios.get('/users')
                   						.then((response)=>{
                     							 context.commit("SET_USERS",response.data)
                         						})
                   					 .catch(err => {
                         						console.log(err)
                         						})
                       },
                     updateUser({commit}, user) {
                               axios.put('/users/' + user.id , user)
                                   .then(res => {
                                       commit('UPDATE_USER', res.data)
                                   }).catch(err => {
                                   console.log(err)
                               })
                           },
                     addUser({commit}, user) {
                               axios.post('/users', user)
                               .then(response => {
                                     commit('ADD_USER', response.data)
                                        })
                               .catch(err => {
                                     console.log(err)
                                     })
                           },
     },
//-------------------------------------------------------------------------//
   getters: {
     getCalendarRoles(state){
           return state.calendarroles
           	},
    getEvents(state){
          return state.calendarevents
          	},
    getEnabledEvents(state){
          return state.enabledevents
            },
    getRoles(state){
          return state.roles
          	},
    getlecturers(state){
          return state.lecturers
            },
    getEnabledEvents(state){
          return state.enabledevents
          	},
    getCalendarItems(state){
          return state.calendaritems
          	},
    getCalendar(state){
    			return state.calendar
    		 },
    getPermissions(state){
             return state.permissions
          },
    getUsers(state){
        return state.users
        	},
    getFiles(state){
        return state.files
        	},
        }
}
