

require('./bootstrap');

require('alpinejs');

import { createApp } from 'vue';
//import { createApp } from 'vue/dist/vue.esm-bundler.js';
import { createStore } from 'vuex';
// Create a new store instance.
import storeData from "./store/index";
const store = createStore(storeData);

import NewsComponent from './components/news.vue';
import ResourcesComponent from './components/resources.vue';
import CalendarComponent from './components/calendar.vue';
import AdminComponent from './components/admin.vue';
import AdminCalendarComponent from './components/adminCalendar.vue';
import AdminUserComponent from './components/adminUser.vue';
import AdminFileComponent from './components/adminFile.vue';
import CalendarItemsComponent from './components/calendarItems.vue';
import CalendarEventsComponent from './components/calendarEvents.vue';
import FilesComponent from './components/files.vue';

import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import AutoComplete from 'primevue/autocomplete';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import ScrollTop from 'primevue/scrolltop';
import FileUpload from 'primevue/fileupload';
import Listbox from 'primevue/listbox';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

import Toolbar from 'primevue/toolbar';

const app = createApp({});
app.component('calendar-component', CalendarComponent);
app.component('news-component', NewsComponent);
app.component('resources-component', ResourcesComponent);
app.component('admin-component', AdminComponent);
app.component('adminuser-component', AdminUserComponent);
app.component('adminfile-component', AdminFileComponent);
app.component('admincalendar-component', AdminCalendarComponent);
app.component('calendaritems-component', CalendarItemsComponent);
app.component('calendarevents-component', CalendarEventsComponent);
app.component('files-component', FilesComponent);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Button', Button);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Column', Column);
app.component('DataTable', DataTable);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Editor', Editor);
app.component('Fieldset', Fieldset);
app.component('InputMask', InputMask);
app.component('InputText', InputText);
app.component('MultiSelect', MultiSelect);
app.component('Tag', Tag);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ScrollTop', ScrollTop);
app.component('FileUpload', FileUpload);
app.component('Listbox', Listbox);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);

app.use(PrimeVue);
app.use(ToastService);
app.use(store);
app.mount('#app');
