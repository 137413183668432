<template>
<div>
<p v-if="items.roles" class="p-mb-3 title">Materiały</p>

<ScrollTop />
<ScrollPanel>
<div v-for="role in items.roles" :key="role.id">
	<div class="p-my-3 p-text-bold p-text-center p-ai-center title_role"><i class="pi pi-angle-right p-mr-2"></i> {{role.name}}</div>
	<Accordion class="accordion-custom" :activeIndex="0">
		<AccordionTab v-for="item in role.items" :key="item.id" >
			<template #header>
				<i class="pi pi-calendar"></i>
				<span class="p-ml-3">{{item.name}}</span>
			</template>
			<span v-for="file,key in items.files[item.id]" :key="file.id" >
			<a :href="catalog_name(file)">
	      <Chip :label="file_name(file)" icon="pi pi-file-o"  class="p-m-1"  />
			</a>
			</span>
			</AccordionTab>
	</Accordion>
</div>
<ScrollTop target="parent" :threshold="100" class="custom-scrolltop" icon="pi pi-arrow-up" />
</ScrollPanel>
</div>
</template>
<script>
export default {
	data() {
		return {
      items: {
				files: null,
				roles: null
			},
	   }
	 },
	methods: {
		file_name(file) {
			return file.replace(/.*\//, '');
		},
		catalog_name(file){
			var res = file.replace("public", "storage");
			return res;
		}
	},
	mounted() {
    axios
      .get('/resources')
      .then(response => {
        this.items = response.data
      })
      .catch(error => {
        console.log(error)
      })
	}
}
</script>

<style scoped>
.title_role {
  color: #2196f3;
  font-size: 1.5em;
}
.title {
  font-size: 1.5em;
}

</style>
