<template>
  <div v-for="item in items" :key="item.id" >
    <div v-if="item.id" class="title">{{item.name}}:</div>
  <div v-for="file in item.files" :key="file.id" >
  <a :href="'/download/'+file.id">
    <Chip :label="file.name" icon="pi pi-file-o" class="p-m-1" />
  </a>
</div>
</div>
</template>

<script>
export default {
    data() {
        return {
          items: {
            files: []
          },
        }
    },
    created() {
    },
    mounted() {

        axios.get('/getfiles')
          .then(response => {
            this.items = response.data
          })
          .catch(error => {
            console.log(error)
          })

    },
    computed: {

    },
    methods: {

    }
}
</script>
<style scoped>

.title{
  font-size: 1.2em;
}
</style>
