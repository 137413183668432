<template>
  <div>
    <Toolbar>
    <template #left>
      <span>Filtr: </span>
      <Dropdown class="p-ml-5" v-model="selectrole" :options="getAllRoles()" optionLabel="name" :showClear="true" placeholder="Edycja" @change="rolechange"/>
      <Dropdown class="p-ml-2" v-model="selectevent" :options="getAllEvents()" optionLabel="name" :filter="true" :showClear="true" placeholder="Szkolenie" @change="eventchange" />
    </template>
    </Toolbar>
    <div class="card p-pt-3">

      <div v-for="item,key in getAllItems" :key="item.id">
        <Divider align="center">
          <div class="p-d-inline-flex p-ai-center">
              <i class="pi pi-calendar p-mr-2 date_color"></i>
              <b class="date_color">  {{getstrdate(key).name}}</b>
          </div>
        </Divider>

        <div v-for="i in item" :key="item.id">
          <p>{{getdatePL(i.start_at)}} ({{i.subtitle}}) - <span class="p-text-bold">{{i.event.name}}</span> - <span class="p-text-bold role_color">{{i.role.name}}</span> </p>
          <div class="p-d-flex p-ai-center p-ml-4 p-my-2" v-if="i.item_lecturer">
            wykładowca:
            <Chip :label="j.name" :image="'images/lecturers/' + j.id + '.jpg'" class="p-mx-2 custom-chip" v-for="j in i.item_lecturer" :key="j.id" />
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>
export default {
    data() {
        return {
          selectrole: null,
          selectevent: null,
          month: [
           {id:0, name:'styczeń'},
           {id:1, name:'luty'},
           {id:2, name:'marzec'},
           {id:3, name:'kwiecień'},
           {id:4, name:'maj'},
           {id:5, name:'czerwiec'},
           {id:6, name:'lipiec'},
           {id:7, name:'sierpień'},
           {id:8, name:'wrzesień'},
           {id:9, name:'pazdziernik'},
           {id:10, name:'listopad'},
           {id:11, name:'grudzień'},
         ],
        }
    },
    created() {
    },
    mounted() {
      this.$store.dispatch("getCalendarRoles")
      this.$store.dispatch("enabledEvents")
      this.$store.dispatch("getCalendar")
    },
    computed: {
    getAllItems() {
      if (this.selectrole != null) {
        var f = this.$store.getters.getCalendar.filter(item => item.role_id == this.selectrole.id)
      }
      else if (this.selectevent != null) {
        var f = this.$store.getters.getCalendar.filter(item => item.event_id == this.selectevent.id)
      }
      else {
        var f = this.$store.getters.getCalendar
      }
      var group = _.groupBy(f, function(item){ return item.start_at.substring(0,7);})
        return group
      },
    },
    methods: {
      rolechange(){
        this.selectevent = null
        this.selectlecturers = null
      },
      eventchange(){
        this.selectrole = null
        this.selectlecturers = null
      },
      changelecturers(){
        this.selectevent = null
        this.selectrole = null
      },

      getAllEvents() {
          return this.$store.getters.getEnabledEvents
        },
      getAllRoles() {
          return this.$store.getters.getCalendarRoles
        },
        getstrdate(d) {
            var date = new Date(d);
            var m = date.getMonth(); //Month from 0 to 11
            const found = this.month.find(element => element.id == m);
            return found;
          },
        getdatePL(d) {
          var date = new Date(d);
          var d = date.getDate();
          var m = date.getMonth() + 1; //Month from 0 to 11
          var y = date.getFullYear();
          return (d <= 9 ? '0' + d : d) + '-' + (m<=9 ? '0' + m : m) + '-' + y;
        },
    }
}
</script>

<style scoped>
.role_color {
  color: #2196f3;
}
.date_color {
  color: #d32f2f;
}
.title {
  font-size: 1.5em;
}
</style>
