<template>
  <div class="card">
    <FileUpload name="files[]" url="/upload" :multiple="true" @upload="onUpload">
    </FileUpload>
  </div>

  <div class="p-formgroup-inline p-m-3">
      <div class="p-field">
          <label for="files" class="p-sr-only">Pliki</label>
          <Listbox id="files" v-model="selectedFiles" :options="getAllFiles" optionLabel="name" :multiple="true"/>
      </div>
      <div class="p-field">
        <div v-for="permission of permissions" :key="permission.id" class="p-field-checkbox">
          <Checkbox :id="permission.id" name="name" :value="permission.id" v-model="selectedPermission" />
          <label :for="permission.id">{{permission.name}}</label>
      </div>
      </div>

    <Button type="button" label="Submit" />
  </div>






</template>

<script>
  export default {
    data() {
	     return {
		       selectedFiles: null,
           selectedPermission: [],
           permissions: null,
	         }
         },
     mounted() {
       this.$store.dispatch("getFiles")
       axios.get('/getpermissions')
         .then(response => {
           this.permissions = response.data
         })
         .catch(error => {
           console.log(error)
         })
         },
     computed: {
       getAllFiles () {
         return this.$store.getters.getFiles
         }
       },
    methods: {
        onUpload() {
          this.$store.dispatch("getFiles")
          },
      getAllPaces() {

      }
  }
}
</script>
