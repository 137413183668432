<template>
<div>
    <Toast />
    <DataTable :value="getAllItems" :scrollable="true" scrollHeight="500px" :lazy="true">
        <template #header>
            <div class="p-d-flex card">
                <span class="p-ml-5">Terminy</span>
                <Button label="Nowy" icon="pi pi-plus" class="p-mr-4 p-ml-auto p-button-success p-button-sm " @click="openNew" />
            </div>
        </template>
        <Column field="id" header="ID"></Column>
        <Column field="start_at" header="Data"></Column>
        <Column field="subtitle" header="Ilość dni"></Column>
        <Column field="event.name" header="Nazwa"></Column>
        <Column field="role.name" header="Edycja"></Column>
        <Column>
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" />
            </template>
        </Column>
    </DataTable>

    <Dialog :visible.sync="eventDialog" header="Szczegóły terminu" :contentStyle="{'overflow': 'visible'}" :style="{width: '1100px'}" :modal="true">

    <div class="p-d-flex p-flex-column">
      <div class="p-mb-3">
        <InputMask mask="9999-99-99" v-model="item.start_at" placeholder="9999-99-99" slotChar="yyyy-mm-dd" />
      </div>
      <div class="p-mb-3">
          <label for="subtitle">Subtitle</label>
          <InputText id="subtitle" v-model="item.subtitle" />
      </div>
      <div class="p-mb-3">
        <Dropdown v-model="item.event_id" :options="getAllEvents" optionLabel="name" optionValue="id" :filter="true" :showClear="true" placeholder="Wybierz szkolenie" />
      </div>
      <div class="p-mb-3">
        <Dropdown v-model="item.role_id" :options="getAllRoles" optionLabel="name" optionValue="id" placeholder="Wybierz edycję" />
      </div>
      <div class="p-mb-3">
        <MultiSelect v-model="item.item_lecturer" :options="getAllLecturers" optionLabel="name" placeholder="Wybierz prowadzącego" />
      </div>
    </div>
    <template #footer>
      <Button label="Anuluj" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Zapisz" icon="pi pi-check" class="p-button-text" @click="saveProduct" />
    </template>
  </Dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        start_at: null,
        subtitle: null,
        event_id: null,
        role_id:0,
        item_lecturer: [],
          },
        eventDialog: false,
        submitted: false,
        vEdit: false,
        }
    },

  mounted() {
    this.$store.dispatch("enabledEvents");
    this.$store.dispatch("allAdminItems")
        this.$store.dispatch("allRoles")
        this.$store.dispatch("allLecturers")
    },
  methods: {

    openNew() {
      this.item = {};
      this.submitted = false;
      this.eventDialog = true;
      },
    hideDialog() {
      this.eventDialog = false;
      this.submitted = false;
      this.vEdit = false;
      },
    editProduct(item) {
      this.item = {
            ...item
        };
      this.eventDialog = true;
      this.vEdit = true;
      },
    saveProduct() {
      if (this.vEdit != true) {
        this.submitted = true;
          if (this.item.start_at.trim()) {
            this.$store.dispatch("addItem", this.item)
            this.$toast.add({
                severity: 'success',
                summary: 'Sukces',
                detail: 'Nowy Termin dodany',
                life: 3000
                });

              }
          } else {
            if (this.item.start_at.trim()) {
                this.$store.dispatch("updateItem", this.item)
                this.$toast.add({
                    severity: 'success',
                    summary: 'Sukces',
                    detail: 'Termin zmieniony',
                    life: 3000
                  });
              }
          }
        this.eventDialog = false;
        this.vEdit = false;
        this.event = {};
        },
    },
  computed: {
    getAllItems() {
        return this.$store.getters.getCalendarItems
      },
    getAllEvents() {
        return this.$store.getters.getEnabledEvents
      },
    getAllRoles() {
        return this.$store.getters.getRoles
      },
    getAllLecturers() {
        return this.$store.getters.getlecturers
      },
    }
  }
</script>

<style>

</style>
