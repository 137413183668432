<template>
  <div class="card">

    <TabView class="tabview-custom">
        <TabPanel>
            <template #header>

                <span>Pliki</span>
                <i class="pi pi-file p-ml-1"></i>
            </template>
            <adminfile-component></adminfile-component>
        </TabPanel>
        <TabPanel>
            <template #header>
                <span>Użytkownicy</span>
                <i class="pi pi-user p-ml-1"></i>
            </template>
          <adminuser-component></adminuser-component>
        </TabPanel>
        <TabPanel>
            <template #header>

                <span>Kalendarz</span>
                <i class="pi pi-calendar p-ml-1"></i>
            </template>
            
          <calendaritems-component></calendaritems-component>
          <calendarevents-component></calendarevents-component>
        </TabPanel>
    </TabView>
</div>





</template>
