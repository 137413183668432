<template>
<div class="card">

    <DataTable :value="getAllUsers" :paginator="true" class="p-datatable-customers" :rows="10"
        dataKey="id" v-model:filters="filters" filterDisplay="row"  responsiveLayout="scroll"
        :globalFilterFields="['name', 'email']">

        <template #header>

            <div class="p-d-flex p-jc-end">
                <span class="p-input-icon-left ">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
                <Button icon="pi pi-user-plus" class="p-button-rounded p-button-success p-mr-2 p-ml-4" @click="addUser()" />
            </div>
        </template>
        <template #empty>
            No customers found.
        </template>
        <template #loading>
            Loading customers data. Please wait.
        </template>
        <Column field="id" header="Id" :sortable="true" style="min-width:16rem"></Column>
        <Column field="name" header="Nazwa" :sortable="true" style="min-width:12rem">
            <template #body="{data}">
                {{data.name}}
            </template>
        </Column>
        <Column field="email" header="Email" style="min-width:16rem"></Column>
        <Column header="Przywileje">
          <template #body="{data}">
            <div v-for="item in data.permission">{{item.name}}</div>
          </template>
        </Column>
        <Column header="Role">
          <template #body="{data}">
            <div v-for="i in data.roles">{{i.name}}</div>
          </template>
        </Column>
        <Column :exportable="false">
            <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editUser(slotProps.data)" />
            </template>
        </Column>
    </DataTable>
</div>
<Dialog v-model:visible="AddUserDialog" :style="{width: '450px'}" header="Dodaj użytkownika" :modal="true" class="p-fluid">
    <div class="p-field">
        <label for="name">Nazwa</label>
        <InputText id="name" v-model.trim="user.name" required="true" autofocus :class="{'p-invalid': submitted && !user.name}" />
        <small class="p-error" v-if="submitted && !user.name">Name is required.</small>
    </div>
    <div class="p-field">
        <label for="email">Email</label>
        <InputText id="email" v-model.trim="user.email" required="true" :class="{'p-invalid': submitted && !user.email}" />
        <small class="p-error" v-if="submitted && !user.email">Email is required.</small>
    </div>
    <template #footer>
        <Button label="Anuluj" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Zapisz" icon="pi pi-check" class="p-button-text" @click="storeUser" />
    </template>
</Dialog>
<Dialog v-model:visible="userDialog" :style="{width: '450px'}" header="Szczegóły użytkownika" :modal="true" class="p-fluid">
    <div class="p-field">
        <label for="name">Nazwa</label>
        <InputText id="name" v-model.trim="user.name" required="true" autofocus :class="{'p-invalid': submitted && !user.name}" />
        <small class="p-error" v-if="submitted && !user.name">Name is required.</small>
    </div>
    <div class="p-field">
        <label for="email">Email</label>
        <InputText id="email" v-model.trim="user.email" required="true" :class="{'p-invalid': submitted && !user.email}" />
        <small class="p-error" v-if="submitted && !user.email">Email is required.</small>
    </div>
    <div class="p-field">
        <label for="permission">Przywileje</label>
        <MultiSelect id="permission" v-model="user.permission" :options="getAllPermissios()" optionLabel="name" placeholder="Select Permission" display="chip" />
    </div>
    <div class="p-field">
        <label for="role">Grupa</label>
        <MultiSelect id="role" v-model="user.roles" :options="getAllRole()" optionLabel="name" placeholder="Select Role" display="chip" />
    </div>
    <template #footer>
        <Button label="Anuluj" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        <Button label="Zapisz" icon="pi pi-check" class="p-button-text" @click="saveUser" />
    </template>
</Dialog>
</template>
<script>
import {FilterMatchMode,FilterOperator} from 'primevue/api';

export default {
    data() {
        return {
          item: [{ "id": 3, "name": "zarząd", "enabled": 1, "created_at": null, "updated_at": null }],
          userDialog: false,
          AddUserDialog: false,
          submitted: false,
          filters: {
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
          user: {
            permission: []
          },
        }
    },
    created() {

    },
    mounted() {
      this.$store.dispatch("getUsers")
      this.$store.dispatch("allRoles")
      this.$store.dispatch("getPermissions")
    },
    computed: {
      getAllUsers () {
        return this.$store.getters.getUsers
      }
    },
    methods: {
      getAllRole () {
        return this.$store.getters.getRoles
      },
      getAllPermissios () {
        return this.$store.getters.getPermissions
      },
      saveUser() {
            this.submitted = true;
			        if (this.user.name.trim()) {
                if (this.user.id) {
                    this.$store.dispatch('updateUser', this.user)
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Updated', life: 3000})
                  }
                else {
                    this.user.id = this.createId();
                    this.user.code = this.createId();
                    this.user.image = 'user-placeholder.svg';
                    this.user.inventoryStatus = this.user.inventoryStatus ? this.user.inventoryStatus.value : 'INSTOCK';
                    this.users.push(this.user);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'user Created', life: 3000});
                  }
                this.userDialog = false;
                this.user = {};
			           }
        },
        storeUser() {
              this.submitted = true;
  			        if (this.user.name.trim()) {

                      this.$store.dispatch('addUser', this.user)
                      this.$toast.add({severity:'success', summary: 'Successful', detail: 'User Add', life: 3000})

                  this.AddUserDialog = false;
                  this.user = {};
  			           }
          },
      hideDialog() {
            this.userDialog = false;
            this.AddUserDialog = false;
            this.submitted = false;
            this.user = {};
        },
      addUser() {

              this.AddUserDialog = true;
          },
      editUser(user) {
            this.user = {...user};
            this.userDialog = true;
        },
    }
}
</script>
