<template>
<div>

    <Card class="p-mt-2">
        <template #content>
          <calendaritems-component />

        </template>
    </Card>
</div>
</template>
